:root {
  --primary-bg: #d0e6eb;
  --primary-bg-text: #000;
  --avatar-bg: rgb(22, 21, 20);
  --receiver-msg-bg: #22343E;
  --sender-msg-bg: #F2F4F7;
  --sender-msg-text: #101828;
  --spinner-color: #475467;
  --view-details-border: transparent;
  --bot-lable-text: grey;
  --bot-lable-bg: #F2F4F7;
  --crousal-shadow: rgba(199, 199, 199, 0.7);
  --img-prev-bg: #F2F4F7;
  --container-border-color: lightgrey;
}

@font-face {
  font-family: 'RubikSprayPaint';
  src: url('/public/RubikSprayPaint-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  letter-spacing: 0.5px;
}

.lbt_bot .lcb_chat-bubble .lcb_chat-bubble-reciever p {
  margin: 0;
}

.lcb_chat-bubble ol, .lcb_chat-bubble ul {
  margin-left: 15px;
}

.lbt_bot .lcb_chat-container {
  position: fixed;
  bottom: 0;
  left: 50px;
  z-index: 2147483642;
  background-color: var(--primary-bg);
  color: var(--primary-bg-text);
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  max-width: 450px;
  border: 1px solid var(--container-border-color);
  border-radius: 8px;
  box-shadow: 0px 0px 100px 60px rgba(16, 24, 40, 0.089);
}
@media (max-width: 768px) {
  .lbt_bot .lcb_chat-container {
    left: 0;
  }
}

.lbt_bot .lcb_chat-container-desktopOpenView {
  position: fixed;
  bottom: 0px;
  left: 10px;
  z-index: 2147483642;
  background-color: var(--primary-bg);
  color: var(--primary-bg-text);
  width: 120vw;
  height: 70%;
  overflow: hidden;
  max-width: 450px;
  border: 1px solid var(--container-border-color);
  border-radius: 8px;
  box-shadow: 0px 24px 48px -12px #1018282E;
}

.lbt_bot .lcb_chat-container-desktopOpenView-fullscreen {
  position: fixed;
  bottom: 0;
  left: 0px;
  z-index: 2147483642;
  background-color: var(--primary-bg);
  color: var(--primary-bg-text);
  width: 100vw;
  height: 85%;
  overflow: hidden;
  max-width: 450px;
}

.lbt_bot .lcb_chat-container-desktopOpenView-fullscreen {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

.lbt_bot .lcb_chat-container-desktop {
  position: fixed;
  bottom: 0px;
  left: 60px;
  z-index: 2147483640;
  background-color: var(--primary-bg);
  color: var(--primary-bg-text);
  width: 100vw;
  overflow: hidden;
  max-width: 250px;
  border: 1px solid var(--container-border-color);
  border-radius: 8px;
  box-shadow: 0px -10px 28px -4px #1018280F;
}

.lbt_bot .lcb_chat-container-desktop.mobile {
  bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.lbt_bot .lcb_chat-container-desktop.mobile .lcb_chat-header-wrapper {
  margin-top: -8px;
  margin-left: 4px;
}

.lbt_bot .lcb_chat-container-desktop .lcb_chat-header-wrapper {
  height: auto;
}

.lcb_bot-name {
  display: flex;
  gap: 8px;
  align-items: center;
}

.lcb_bot-name .lcb_headertext {
  font-family: 'RubikSprayPaint', Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

@media only screen and (max-width: 365px) {
  .lcb_bot-name {
    gap: 4px !important;
    font-size: 14px !important;
  }

  .lbt_bot .lcb_humanJoinedContainer {
    margin: 12px !important;
  }
}

.lbt_bot .lcb_humanJoinedContainer {
  margin: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 15px;
  font-size: 12px;
}

.lbt_bot .lcb_humanJoinedContainer .lcb_lineForNewJoined {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #D0D5DD;
  top: 50% !important;
  z-index: -1;
  top: 7px;
}

.lbt_bot .lcb_labelForNewJoined {
  background: var(--primary-bg);
  width: auto;
  padding: 0 15px;
  text-align: center;
  right: 50%;
  top: 50%;
}

.lbt_bot .lcb_sender-container {
  display: flex;
  justify-content: left; /* Align items in the center horizontally */
  align-items: flex-start;
  padding: 10px;
}

.lcb_sender-container .lcb_buttons-container {
  display: flex;
  justify-content: center; /* Ensure buttons are centered within the container */
  align-items: center;
  gap: 10px;
  width: 100%; /* Ensure the container takes full width to center content properly */
}

.lcb_buttons-container .lcb_cta-button {
  padding: 10px 20px; /* Add padding to the button */
  font-size: 16px; /* Set font size */
  color: #FFFFFF; /* Set text color */
  background-color: #007BFF; /* Set background color */
  border: none; /* Remove border */
  border-radius: 5px; /* Add rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  text-align: center; /* Center the text inside the button */
}
.lbt_bot .lcb_message-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 16px;
  height: calc(100% - 200px);
  overflow-x: hidden;
  overflow-y: scroll;
  justify-content: space-between;
}

.lbt_bot .lcb_message-container::-webkit-scrollbar {
  display: none;
}

.lbt_bot .lcb_message-container-fullscreen {
  margin-left: 25rem;
  margin-right: 25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.lbt_bot .lcb_input-message form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.lcb_inputTextField {
  margin: 0 !important;
}

.lbt_bot .lcb_input-message textarea, .lcb_inputTextField {
  margin: 16px 3px;
  margin-right: 10px;
  display: flex;
  max-height: 140px;
  height: 44px;
  min-height: 44px;
  padding: 11px 50px 8px 16px;
  font-size: 14px;
  line-height:16px;
  align-items: center;
  gap: 8px;
  resize: none;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--primary-bg);
  color: var(--primary-bg-text);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.lbt_bot .lcb_input-message textarea:focus, .lcb_inputTextField:focus {
  box-shadow: 0px 0px 8px 2px rgba(16, 24, 40, 0.2);
  transform: scale(1.02);
}

.lbt_bot .lcb_input-message textarea::-webkit-scrollbar {
  width: 4px;
}

.lbt_bot .lcb_input-message textarea::-webkit-scrollbar-track {
  background-color: rgb(226, 225, 221);
  border-radius: 8px;
}

.lbt_bot .lcb_input-message textarea::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 8px;
}
.lbt_bot .lcb_sendButton {
  flex-basis: 20%;
  border-radius: 6px;
  border: none;
  padding: 7px 8px;
  max-height: 42px;
  text-align: center;
  text-decoration: none;
  display: inline-flex; /* Changed from inline-block to inline-flex */
  align-items: center; /* Added to vertically center content */
  justify-content: center; /* Added to horizontally center content */
  font-size: 16px;
  background: transparent;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Added transition for smooth effect */
}

.lbt_bot .lcb_sendButton {
  color: var(--primary-bg-text);
  opacity: 0.5;
  position: absolute;
  right: 12px;
  top: 50%; /* Adjusted to 50% for vertical centering */
  transform: translateY(-50%); /* Added to correctly center the button vertically */
  cursor: pointer;
}

.lcb_sendButton img {
  max-width: 100% !important;
  width: 24px !important;
  transition: transform 0.2s ease-in-out; /* Ensure the image also transitions smoothly */
}

.lcb_sendButton:hover, .lcb_sendButton.highlight { /* Added .highlight class for additional state */

  opacity: 1; /* Increase opacity for better visibility */
  svg, img {
    transform: scale(1.30); /* Make the image/icon slightly larger than the button for emphasis */
  }
}

.lcb_uploading-indicator {
  width: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.lcb_uploading-indicator svg{
  color: var(--primary-bg-text);
  width: 30px;
}

.lbt_bot .lcb_chat-bubble {
  padding: 0px 15px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 12px 0 12px 12px;
}

.lbt_bot .lcb_chat-bubble-sender {
  background: var(--sender-msg-bg);
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  color: var(--sender-msg-text);
  justify-content: flex-end;
  border-radius: 0px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
}

.lcb_chat-feedback-section {
  display: flex;
  gap: 15px;
  margin-left: 10px;

  span {
    cursor: pointer;
  }

  span:hover {
    opacity: 0.7;
  }
}

.lbt_bot .lcb_wrapperOfBotInitials .lcb_botLinks {
  font-weight: 700;
  color: var(--primary-bg-text);
}

.lbt_bot .lcb_chat-bubble-reciever {
  background: var(--receiver-msg-bg);
  align-self: flex-end;
  max-width: 80% !important;
  width: fit-content;
  /* color: var(--primary-bg); need to add another variable*/
  color: white;
  justify-content: flex-end;
  border-radius: 8px 0px 8px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
  margin-left: auto;
}

.lbt_bot .lcb_chat-bubble-reciever .lcb-uploaded-img {
  height: 230px;
  width: 230px;
}

.lbt_bot .lcb_chat-bubble-reciever img {
  width: 100%;
  height: 100%;
  background: var(--sender-msg-bg);
  object-fit: contain;
  border-radius: 15px;
}

.lbt_bot .lcb_chat-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--primary-bg);
  color: var(--primary-bg-text);
  font-weight: bold;
  border-radius: 8px;
  margin: 10px;
  height: 72px;
  cursor: pointer;
}

.lbt_bot .lcb_user-avatar {
  min-width: 40px !important;
  height: 40px;
  border-radius: 50%;
  background-color: var(--avatar-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-right: 15px;
}

.lbt_bot .lcb_imageWithText {
  width: 250px;
  height: auto;
  border-radius: 16px;
}

.lbt_bot .lcb_input-message {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--primary-bg);
}

.lbt_bot .lcb_input-message .lcb-consent-msg {
  margin: 0px 5px 5px 6px;
  font-size: 11px;
}

.lbt_bot .lcb_input-message .lcb-consent-msg.in-full-screen {
  display: flex;
  justify-content: center;
}

.lbt_bot .lcb_inputText {
  position: relative;
}

.lcb_chat-header-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin-right: 5px;
}

.lcb_chat-header-btn-wrapper span:hover {
  svg {
    opacity: 0.5;
  }
}

/* Container to hold the images */
.lbt_bot .lcb_image-container {
  width: 100%;
  overflow: hidden;
  padding: 0;
  display: flex;
  gap: 1em;
  overflow-x: scroll;
}

.lbt_bot .lcb_image-container::-webkit-scrollbar {
  display: none;
}

/* Each image wrapper */

.lbt_bot .lcb_image-wrapper {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  background: var(--sender-msg-bg);
  color: var(--primary-bg-text);
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0px 0px 10px 3px var(--crousal-shadow);
}

.lbt_bot .lcb_image-wrapper svg {
  background-color: var(--primary-bg);
}

/* The actual images */
.lbt_bot .lcb_image-wrapper img, .lbt_bot .lcb_image-wrapper svg {
  height: auto;
  /* Maintain aspect ratio */
  width: 190px;
  max-width: 190px !important;
  height: 177px;
  border-radius: 8px;
  object-fit: cover;
}

.lbt_bot .lcb_image-wrapper .lcb_img-title {
  top: 188px;
  left: -1px;
  color: var(--primary-bg-text);
  font-size: 14px;

  /* //styleName: Text sm / Bold; */
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.lbt_bot .lcb_image-wrapper .lcb_img-text {
  /* //styleName: Text xs/Regular; */
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.lbt_bot .lcb_headertext_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lbt_bot .lcb_botInitialMessage {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px 0px 8px 8px;
  background: rgba(65, 102, 123, 0.20);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  margin: 2px;
}

.lbt_bot .lcb_wrapperOfBotInitials {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  overflow-x: scroll;
  cursor: all-scroll;
}

.lbt_bot .lcb_wrapperOfBotInitials::-webkit-scrollbar {
  display: none;
}

.lbt_bot .lcb_convoByBotWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  bottom: 60px;
  right: 16px;
}

.lbt_bot .lcb_img-title {
  top: 469px;
  left: 16px;

  /* //styleName: Display xs/Semibold; */
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

/* Image Data container */

span .lcb_img-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-top: 24px;
  color: var(--primary-bg-text);
}

span.lcb_readMoreInImagePage {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--primary-bg-text);
  cursor: pointer;
}

@media only screen and (min-width: 1200px) {
  .lbt_bot .lcb_chat-container-desktopOpenView-fullscreen .lcb_message-container, .lbt_bot .lcb_chat-container-desktopOpenView-fullscreen .lcb_input-message form {
    width: 700px;
    margin: 16px auto;
  }

  .lbt_bot .lcb_chat-bubble {
    max-width: max-content;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

  .lbt_bot .lcb_chat-container-desktopOpenView-fullscreen .lcb_message-container, .lbt_bot .lcb_chat-container-desktopOpenView-fullscreen .lcb_input-message form {
    width: 700px;
    margin: 16px auto;
  }

  .lbt_bot .lcb_chat-bubble {
    max-width: max-content;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .lbt_bot .lcb_chat-container-desktopOpenView-fullscreen .lcb_message-container, .lbt_bot .lcb_chat-container-desktopOpenView-fullscreen .lcb_input-message form {
    width: 700px;
    margin: 14px auto;
  }

  .lbt_bot .lcb_chat-bubble {
    max-width: max-content;
  }
}

.lcb_attachFile {
  margin: 7px 7px 0px 7px;
  cursor: pointer;
}

.lcb_attachFile>input {
  display: none !important;
}

.lcb_attachFile:hover {
  svg {
    opacity: 0.5;
  }
}

.lcb_company-logo-img {
  height: 35px;
}

/* its class which is in AI's messages (resp form api) */
.footnotes {
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #8080808f;
  font-size: 0.8em;
}

.lcb_chat-bubble .footnote {
  color: var(--primary-bg-text);
  opacity: 0.8;
}

/* added css for all p tag in message contents */
.lcb_chat-bubble>span>p {
  margin-bottom: 12px !important;
  margin-left: 5px;
}

.lcb_chat-bubble>span p {
  margin-bottom: 5px;
}

.lcb_chat-bubble>span>table {
  margin-bottom: 10px;
  counter-reset: row-counter;
}

.lcb_chat-bubble>span>table thead tr {
  padding-left: 4px;
}

.lcb_chat-bubble>span>table thead tr::before {
  content: 'Sr No.';
  padding-left: 4px;
  font-weight: bold;
}

.lcb_chat-bubble>span>table tbody tr {
  counter-increment: row-counter;
  position: relative;
}

.lcb_chat-bubble>span>table tbody tr::before {
  content: counter(row-counter) ".";
  /* Display the counter value followed by a period and space */
  border-top: 1px solid #808080;
  padding-left: 4px;
  display: flex;
}


.lcb_chat-bubble>span>table, .lcb_chat-bubble>span>table th, .lcb_chat-bubble>span>table td {
  border: 1px solid #808080;
  border-collapse: collapse;
  padding: 5px;
}

.lcb_img-prev {
  position: absolute;
  bottom: 75px;
  height: 230px;
  width: 230px;
}

.lcb_img-prev-full-screen {
  left: 125px
}

.lcb_img-prev .cross-icon {
  position: absolute;
  left: 210px;
  top: 3px;
  cursor: pointer;
}

.lcb_img-prev img {
  width: 100%;
  height: 100%;
  background: var(--img-prev-bg);
  object-fit: contain;
  border-radius: 15px;
}

.lcb_bot-fontstyle {
  width: max-content;
  height: 22px;
  background: var(--bot-lable-bg);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: var(--bot-lable-text);
  padding: 2px 8px 2px 8px;
  border-radius: 16px
}

.lcb_imageOpenerWrapper {
    position: fixed;
    bottom: 0;
    z-index: 100000000;
    height: 100vh;
    background: var(--primary-bg);
}

.lcb_imageOpenerWrapper.fullScrreen {
    width: 100%;
}

.lbt_bot .lcb_chat-header-wrapper-prod-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-bg);
    color: var(--primary-bg-text);
    font-weight: bold;
    border-radius: 8px;
    height: 72px;
    margin: 0;
    padding: 16px;
}

.lcb_image-page-container {
    display: flex;
    flex-direction: column;
    margin: 10px 16px;
    gap: 15px;
}

.lcb_image-page-container.fullScrreen {
    flex-direction: row;
}

.lcb_single-img-text {
    top: 517px;
    left: 16px;

    /* //styleName: Text md/Semibold; */
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    /* //styleName: Text md/Regular; */
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}


.lcb_single-img-text ul {
    margin-left: 30px;
}

.lcb_product-detail {
    display: flex;
    flex-direction: column;
    gap: 3px;
    overflow-y: auto;
}

.lcb_product-detail.with-full-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    flex: 30%;
}

@media screen and (min-width: 400px) {
    .lcb_single-img-text {
        overflow-y: auto;
        max-height: 300px;
    }
}

@media screen and (max-width: 400px) {
    .lcb_single-img-text {
        overflow-y: auto;
        max-height: 210px;
    }
}

.lcb_consultation {
    padding: 15px;
    font-size: 17px;
    border-radius: 38px;
    background: var(--receiver-msg-bg);
    color: #FFF;
    cursor: pointer;
}

.lcb_consultation.in-full-view {
    width: 220px !important;
}

/* Custom css for Crousal  */

.carousel-root {
    flex: 75% !important;
}

.lcb_image-page-container .carousel.carousel-slider {
    max-height: 40vh;
    border-radius: 10px;
}

.lcb_image-page-container.fullScrreen .carousel.carousel-slider {
    height: 80vh;
    border-radius: 10px;
}

.lcb_back-arrow {
    margin-right: 16px;
    cursor: pointer;
}

.lcb_spinner {
    margin-left: 40px;
    width: 70px;
    text-align: center;
}

.lcb_spinner>div {
    width: 8px;
    height: 8px;
    margin-right: 4px;
    background-color: var(--spinner-color);

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.lcb_spinner .lcb_bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.lcb_spinner .lcb_bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.lcb_headertext .lcb_spinner{
    margin-left: 0 !important;
}
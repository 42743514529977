.lcb-popup {
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.lcb-popup-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.lcb-popup-inner .lcb-popup-cross-icon {
    position: absolute;
    bottom: 126px;
    cursor: pointer;
    right: -10px;
    color: #0f0092;
    z-index: 999;
}

.lcb-subscribe {
    position: relative;
    height: 140px;
    width: 270px;
    padding: 20px;
    background-color: #FFF;
    border-radius: 4px;
    color: #333;
    box-shadow: 0px 0px 60px 5px rgba(0, 0, 0, 0.4);
}

.lcb-subscribe:after {
    position: absolute;
    content: "";
    right: -10px;
    bottom: 18px;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #1a044e;
}

.lcb-subscribe p {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.lcb-subscribe input {
    position: absolute;
    bottom: 30px;
    border: none;
    border-bottom: 1px solid #d4d4d4;
    padding: 10px;
    width: 82%;
    background: transparent;
    transition: all .25s ease;
}

.lcb-subscribe input:focus {
    outline: none;
    border-bottom: 1px solid #0d095e;
    font-family: inherit;
}

.lcb-subscribe .lcb-submit-btn {
    position: absolute;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    background-color: #0f0092;
    color: #FFF;
    padding: 12px 25px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 5px;
    right: -10px;
    bottom: -20px;
    cursor: pointer;
    transition: all .25s ease;
    box-shadow: -5px 6px 20px 0px rgba(26, 26, 26, 0.4);
}

.lcb-subscribe .lcb-submit-btn:hover {
    background-color: #07013d;
    box-shadow: -5px 6px 20px 0px rgba(88, 88, 88, 0.569);
}

.lcb-email-input-error {
    font-size: 12px;
    font-family: inherit;
    color: red;
    position: absolute;
    bottom: 10px;
}
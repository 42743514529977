.lcb_dark-theme {
    --primary-bg: #111B21;
    --primary-bg-text: #fff;
    --avatar-bg: #E078F9;
    --receiver-msg-bg: #B800DF;
    --sender-msg-bg: rgba(65, 102, 123, 0.50);
    --sender-msg-text: #fff;
    --spinner-color: #767FFF;
    --bot-lable-text: #fff;
    --bot-lable-bg: #767FFF;
    --crousal-shadow: rgba(84, 84, 84, 0.7);
    --img-prev-bg: rgba(65, 102, 123);
    --container-border-color: #6B6B6B;
}

.lcb_dark-theme .footnotes a {
    color: #b398ec;
}

.lcb_dark-theme .footnotes a:visited {
    color: #2B39FF;
}
